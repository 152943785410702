<template>
  <div>
    <el-dialog title="新增" :visible.sync="addVisible" :before-close="closeEvent" width="600px">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="上级名称:" required>
          <p v-text="parentName" style="width: 435px"></p>
        </el-form-item>
        <el-form-item size="small" label="名称:" required>
          <el-input v-model="menuName" maxlength="12"style="width: 435px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="菜单权限:" required>
          <el-cascader
                  style="width: 435px"
              :options="pOptions"
              v-model="permissionKey"
              :show-all-levels="false"
              change-on-select
          ></el-cascader>
        </el-form-item>
        <el-form-item size="small" label="请求接口:" required>
          <el-input v-model="menuAction" maxlength="128" style="width: 435px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="图标ICON:" required>
          <el-input v-model="icon" maxlength="8" style="width: 435px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="排序值:" required>
          <el-input v-model="sort" maxlength="2" style="width: 435px" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="saveMenu">确 定</el-button>
          <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
import {saveMenu, getPermissions} from '../../../api/sys/menu'
import {dataFormat} from '../../../utils/main';

export default {
    data() {
        return {
            list: [],
            sort: "",
            icon: "",
            menuName: "",
            permissionKey: [],
            menuAction: "",
            pOptions: []
        }
    },
    props: {
        id: String,
        parentName: String,
        addVisible: Boolean,
        options: Object
    },
    created() {
        this.getPermissions();
    },
    methods: {
        closeEvent() {
            this.$emit('closeInsert')
        },
        async getPermissions() {
            let res = await getPermissions();
            let data = dataFormat(this.options, res)
            this.pOptions = data.list;
        },
        async saveMenu() {
            let _permissionKey = this.permissionKey[this.permissionKey.length - 1];
            let data = {
                parentId: this.id,
                menuName: this.menuName,
                sort: this.sort,
                icon: this.icon,
                permissionKey: _permissionKey,
                menuAction: this.menuAction
            };
            let res = await saveMenu(data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('addSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
