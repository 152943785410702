<template>
  <el-dialog title="编辑" :visible.sync="editVisible" width="600px" :before-close="closeEvent">
    <el-form ref="form" label-width="100px">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="上级名称:" required>
          <p v-text="editData.parentName"></p>
        </el-form-item>
        <el-form-item size="small" label="名称:" required>
          <el-input v-model="editData.menuName" maxlength="12" style="width: 435px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="菜单权限:"  required>
          <el-cascader
                  style="width: 435px"
              :options="pOptions"
              v-model="permissionKey"
              :show-all-levels="false"
              change-on-select
          ></el-cascader>
        </el-form-item>
        <el-form-item size="small" label="请求接口:" required>
          <el-input v-model="editData.menuAction" style="width: 435px" maxlength="128" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="图标ICON:" required>
          <el-input v-model="editData.icon" style="width: 435px" maxlength="8" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="排序值:" required>
          <el-input v-model="editData.sort" style="width: 435px" maxlength="2" show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="updateMenu">确 定</el-button>
        <el-button size="small" @click="closeEvent">取 消</el-button>
      </span>
  </el-dialog>
</template>
<script>
import {updateMenu, getPermissions} from '../../../api/sys/menu';
import {dataFormat} from '../../../utils/main';

export default {
    data() {
        return {
            show: false,
            pOptions: [],
            permissionKey: [],
            permissMap: {}
        }
    },

    props: {
        editData: Object,
        editVisible: Boolean,
        options: Object
    },
    created() {
        this.getPermissions();
    },
    methods: {
        async getPermissions() {
            let res = await getPermissions();
            let data = dataFormat(this.options, res)
            this.pOptions = data.list;
            this.permissMap = data.map;
            this.deepParent(this.editData.permissionKey);
        },
        closeEvent() {
            this.$emit('closeEdit')
        },
        async updateMenu() {
            let _permissionKey = this.permissionKey[this.permissionKey.length - 1];
            let data = {
                id: this.editData.id,
                parentId: this.editData.pId,
                menuName: this.editData.menuName,
                icon: this.editData.icon,
                sort: this.editData.sort == null ? '' : this.editData.sort,
                permissionKey: _permissionKey,
                menuAction: this.editData.menuAction
            };
            let res = await updateMenu(data);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.$emit('editSuccess')
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        deepParent(id, arr) {
            if (!id) return [];

            if (!arr) {
                this.permissionKey = [];
            }
            this.permissionKey.unshift(id);
            if (this.permissMap[id] && this.permissMap[id].parentId) {
                let parentId = this.permissMap[id].parentId;
                this.deepParent(this.permissMap[parentId].id, this.permissionKey)
            }
        }
    }
}
</script>

<style scoped>

</style>
