<template>
  <div>
    <div class="e-breadcrumb">菜单管理</div>
    <div class="e-card">
      <div class="e-card-body" style="padding-top:35px">
        <el-button type="primary" size="small" @click="addRootEvent">添加菜单</el-button>
        <div class="container" style="width:400px;margin-top:10px;padding: 10px;">
          <v-tree :nodes="nodes">
            <template slot-scope="{node}">
              <a href="javascript:;" @click.stop="addEvent(node)" class="v-tree-plus"></a>
              <a href="javascript:;" @click.stop="editEvent(node)" class="v-tree-edit"></a>
              <a href="javascript:;" @click.stop="removeEvent(node)" class="v-tree-remove"></a>
            </template>
          </v-tree>
        </div>
      </div>
    </div>

    <el-dialog title="添加" width="600px" :visible.sync="addRootVisible">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="名称:" required>
          <el-input v-model="name" maxlength="12" style="width: 415px" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="排序值:" required>
          <el-input v-model="sort" maxlength="2" style="width: 415px" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button size="small" type="primary" @click="addRoot">确 定</el-button>
          <el-button size="small" @click="addRootVisible = false">取 消</el-button>
        </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="delMenu">确 定</el-button>
        <el-button size="small" @click="delVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess"
            :parentName="parentName" :id="id" :options="menuOptions"/>
    <Edit v-if="editVisible" :editVisible="editVisible" @closeEdit="closeEdit"
          @editSuccess="editSuccess"
          :editData="editData"
          :options="menuOptions"
    />
  </div>
</template>

<script>
import Insert from './insert'
import Edit from './edit'
import {dataFormat} from '../../../utils/main'
import {getMenuList, addRoot, deleteMenu} from '../../../api/sys/menu'
import vTree from '@/components/common/v-tree/tree'

export default {
    data() {
        return {
            name: "",
            sort: "",
            icon: "",
            list: {},
            nodes: [],
            options: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "menuName",
                        children: 'children'
                    }
                }
            },
            menuOptions: {
                data: {
                    simpleData: {
                        idKey: "id",
                        pIdKey: "parentId",
                    },
                    key: {
                        name: "permissionName",
                        children: 'children'
                    }
                }
            },
            id: '',
            parentName: '',
            addRootVisible: false,
            addVisible: false,
            editVisible: false,
            delVisible: false,
            editData: {}
        }
    },
    created() {
        this.searchEvent();
    },
    methods: {
        async searchEvent() {
            let res = await getMenuList();
            let data = dataFormat(this.options, res)
            this.nodes = data.list
            this.list = data.map
            console.log(this.list)
        },
        //添加顶级权限
        async addRoot() {
            let res = await addRoot(this.name, this.sort)
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.addRootVisible = false
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addRootEvent() {
            this.addRootVisible = true
        },

        addEvent(node) {
            this.addVisible = true;
            this.id = node.id
            this.parentName = node.menuName
        },
        editEvent(node, nodes) {
            this.editData = {
                id: node.id,
                pId: node.parentId,
                menuName: node.menuName,
                menuCode: node.menuCode,
                icon: node.icon,
                sort: node.sort,
                menuAction: node.menuAction,
                permissionKey: node.permissionKey,
                parentName: (this.list[node.parentId] ? this.list[node.parentId].menuName : '无')
            };
            this.editVisible = true;
        },
        removeEvent(node) {
            this.id = node.id
            this.delVisible = true
        },
        async delMenu() {
            let res = await deleteMenu(this.id)
            if (res.code == 200) {
                this.searchEvent();
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
                this.delVisible = false
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addSuccess() {
            this.closeInsert();
            this.searchEvent();
        },

        closeInsert() {
            this.addVisible = false;
        },
        editSuccess() {
            this.closeEdit();
            this.searchEvent();
        },
        closeEdit() {
            this.editVisible = false;
        }
    },
    components: {
        Insert,
        Edit,
        vTree
    }
}
</script>

<style scoped>

</style>
